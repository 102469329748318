<template>
  <span v-if="!willHaveValue">-</span>
  <span v-else-if="value">
    {{value}}
  </span>
  <div v-else class="line"></div>
</template>

<script>
export default {
  props: {
    value: String,
    willHaveValue: Boolean
  }
}
</script>
<style lang="scss">
.line {
  width: 30px;
  height: 10px;
  display: inline-block;
  border-radius: 7px;
  animation: loading-skeleton 1s ease infinite;
  background-image: linear-gradient(to right, transparent, rgba(207, 207, 207, 0.5), rgb(207, 207, 207), rgba(207, 207, 207, 0.5), transparent);
  background-size: 150% 150%;
  background-position: 0 0;
}
@keyframes loading-skeleton {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -200%;
  }
}
</style>
